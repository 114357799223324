import {LOGGED_KEY} from '@common/constants';
import {useAppContext} from '@lib/context/AppContext';
import {theme} from '@lib/theme';
import {AppBar, Box, Button, IconButton, Toolbar, Typography, useMediaQuery} from '@mui/material';
import {useRouter} from 'next/router';
import {FC, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {LoadingDialog} from '../dialogs/LoadingDialog';
import {NotificationsComponent} from '../notifications/NotificationsComponent';
import {Div} from '../styledComponents/Div';
import {EgdLogo} from './EgdLogo';

type Props = {
    readonly statusCode?: number;
    readonly children: any;
};

export const PageLayout: FC<Props> = ({children}) => {
    // methods
    const {push} = useRouter();
    const matches = useMediaQuery('(max-width:500px)');
    const {values, closeNotification} = useAppContext();

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // functions
    const handleRouteEGD = () => push('https://www.egd.cz');

    useEffect(() => {
        if (sessionStorage.getItem(LOGGED_KEY) === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    return (
        <Div
            sx={{
                // display: 'flex',
                // flexDirection: 'column',
                backgroundColor: 'background.default',
                position: 'relative',
                // minWidth: 450,
                minHeight: '100vh',
            }}
        >
            {/* Notifications */}
            <NotificationsComponent notifications={values.notifications} onClose={closeNotification} />

            {/* Header */}
            <Div
                sx={{
                    height: 80,
                    display: 'flex',
                    flexFlow: 'row wrap',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingRight: 1,
                    maxWidth: 1680,
                }}
            >
                <IconButton
                    sx={{
                        marginLeft: 5,
                    }}
                    onClick={handleRouteEGD}
                >
                    <EgdLogo width={150} height={50} pathFill={theme.palette.error.main} />
                </IconButton>

                {isAuthenticated && (
                    <Button type="button" variant="contained" onClick={handleRouteEGD}>
                        <FormattedMessage id="button.backToEgd" />
                    </Button>
                )}
            </Div>

            {/* Appbar */}
            <AppBar
                position="relative"
                color="secondary"
                sx={{
                    minHeight: 204,
                    backgroundImage: "url('/static/images/AppBarBackground.svg')",
                    backgroundPosition: 'right',
                    backgroundRepeat: 'no-repeat',
                    display: 'block',
                }}
            >
                <Toolbar
                    sx={{
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '0 auto',
                        maxWidth: 1680,
                    }}
                >
                    <Typography
                        variant={matches ? 'h4' : 'h3'}
                        color="inherit"
                        sx={{
                            marginTop: 2,
                            marginLeft: -2,
                            minWidth: 200,
                            maxWidth: 700,
                            padding: 1,
                            textAlign: matches ? 'center' : 'left',
                        }}
                    >
                        <Box fontWeight="fontWeightBold">
                            <FormattedMessage id="portal" />
                        </Box>
                    </Typography>
                </Toolbar>
            </AppBar>

            {/* Main */}
            <Div
                role="main"
                sx={{
                    display: 'block',
                    padding: '20px 16px 82px',
                    maxWidth: 1450,
                    margin: 'auto',
                    height: '100%',
                }}
            >
                {false ? <LoadingDialog open /> : children}
            </Div>

            {/* Footer */}

            <Div
                sx={{
                    width: '100%',
                    height: 86,
                    left: 0,
                    bottom: 0,
                    position: 'absolute',
                    background: 'linear-gradient(270deg,rgba(225,48,25,0.86) 0%,#261B62 100%)',
                }}
            >
                <Div
                    sx={(theme) => ({
                        paddingTop: theme.spacing(4),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight: 1,
                        maxWidth: 1680,
                        margin: 'auto',
                    })}
                >
                    <EgdLogo width={100} height={33} pathFill={theme.palette.background.default} />
                    <Typography
                        component={'span'}
                        variant="body2"
                        sx={{
                            color: 'background.default',
                        }}
                    >
                        <FormattedMessage id="footer" values={{year: new Date().getFullYear()}} />
                    </Typography>
                </Div>
            </Div>
        </Div>
    );
};
