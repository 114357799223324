import {UseDialogApi} from '@lib/hooks/useDialog';
import {Dialog, DialogContent, DialogTitle, Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {OveritUzivateleForm} from '../forms/OveritUzivateleForm';

type Props = {toggleIsAuthenticated: (value: boolean) => void; handleSetLoading: () => void} & Omit<UseDialogApi, 'open'>;

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-end', // push the dialog to bottom
        },
    },
    paper: {
        // make the content full width
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            maxWidth: '100%',
            width: '100%',
            borderRadius: theme.spacing(3, 3, 0, 0),
            scrollbarWidth: 'none',
        },
    },
    paperFullWidth: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));
/**
 * Dialog pro ověření uživatele
 * @param param0
 * @returns
 */
export const AuthenticateDialog: React.FC<Props> = ({close, isOpen, toggleIsAuthenticated, handleSetLoading}) => {
    const classes = useStyles();
    return (
        <Dialog open={isOpen} classes={{container: classes.root, paper: classes.paper, paperFullWidth: classes.paperFullWidth}} fullWidth>
            <DialogTitle variant="h3" fontSize="1.35rem" fontWeight="500">
                <FormattedMessage id="kontakty.verifyDialogTitle" />
            </DialogTitle>

            <DialogContent sx={{scrollbarWidth: 'none'}}>
                <OveritUzivateleForm closeDialog={close} toggleIsAuthenticated={toggleIsAuthenticated} handleSetLoading={handleSetLoading} />
            </DialogContent>
        </Dialog>
    );
};
