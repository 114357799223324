export const ANONYMOUS_USER_ID_KEY = 'anonymousUserId';

export const getAnonymousUserId = () => {
    const storedAnonymousUserId = sessionStorage.getItem(ANONYMOUS_USER_ID_KEY);

    if (!storedAnonymousUserId) {
        const generatedAnonymousUserId = generateAnonymousUserId();

        sessionStorage.setItem(ANONYMOUS_USER_ID_KEY, generatedAnonymousUserId);

        return generatedAnonymousUserId;
    }

    return storedAnonymousUserId;
};

const generateAnonymousUserId = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
