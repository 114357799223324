import {PageLayout} from '@common/components/layout/PageLayout';
import {LOGGED_KEY} from '@common/constants';
import {useDialog} from '@lib/hooks/useDialog';
import {Kontakty} from '@modules/kontakty/Kontakty';
import {AuthenticateDialog} from '@modules/kontakty/dialogs/AuthenticateDialog';
import {NextPage} from 'next';
import {useEffect, useState} from 'react';
import {LoadingDialog} from '../client/common/components/dialogs/LoadingDialog';

type Props = {
    readonly statusCode?: number;
};

const Index: NextPage<Props> = ({statusCode}) => {
    const [isCheckingAuthorization, setIsCheckingAuthorization] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const hasLoggedKeyInSessionStorage = typeof window !== 'undefined' && sessionStorage.getItem(LOGGED_KEY) === 'true';

    const {isOpen: isAuthenticateDialogOpen, close: closeAuthenticateDialog} = useDialog(!hasLoggedKeyInSessionStorage);

    const toggleAuthentication = (value: boolean) => {
        setIsAuthenticated(value);
    };

    const toggleIsCheckingAuthorization = (isCheckingAuthorization?: boolean) => {
        setIsCheckingAuthorization((prevState) => isCheckingAuthorization ?? !prevState);
    };

    const handleSetLoading = () => setIsLoading((prevState) => !prevState);

    useEffect(() => {
        if (hasLoggedKeyInSessionStorage) {
            setIsCheckingAuthorization(true);

            toggleAuthentication(true);
        }
    }, [hasLoggedKeyInSessionStorage]);

    return (
        <PageLayout statusCode={statusCode}>
            {isLoading && <LoadingDialog open />}
            {isAuthenticated && !isLoading && (
                <Kontakty
                    toggleAuthentication={toggleAuthentication}
                    isCheckingAuthorization={isCheckingAuthorization}
                    toggleIsCheckingAuthorization={toggleIsCheckingAuthorization}
                />
            )}

            {!isAuthenticated && !isLoading && (
                <AuthenticateDialog
                    close={closeAuthenticateDialog}
                    isOpen={isAuthenticateDialogOpen || !isAuthenticated}
                    toggleIsAuthenticated={toggleAuthentication}
                    handleSetLoading={handleSetLoading}
                />
            )}
        </PageLayout>
    );
};

export default Index;
