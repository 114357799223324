import {LOGGED_KEY} from '@common/constants';
import {OveritUzivateleFormData} from '@common/utils/form';
import axios from 'axios';

type OveritUzivateleData = {
    input: OveritUzivateleFormData;
    recaptchaVerificationCode?: string;
    anonymousUserId: string;
};

export const OveritUzivateleService = {
    verify: async (postData: OveritUzivateleData) => {
        const result = await axios.post(`/authenticateUzivatele`, postData);

        sessionStorage.setItem(LOGGED_KEY, 'true');

        return result.data;
    },
};
