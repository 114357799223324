import {useMutation, useQuery} from '@apollo/client';
import {ConfirmDialog} from '@common/components/dialogs/ConfirmDeleteDialog';
import {NotificationType} from '@common/components/notifications/NotificationModel';
import {LOGGED_KEY} from '@common/constants';
import {Kontakt, Mutation, NapetovaUroven, NotifikaceKategorie, Query} from '@eon.cz/apollo13-graphql-web';
import {useAppContext} from '@lib/context/AppContext';
import {DELETE_KONTAKT_MUTATION} from '@lib/graphql/mutations';
import {GET_MY_UCET_SKUPINA_QUERY, ZISKAT_KONTAKTY_QUERY} from '@lib/graphql/queries';
import {useDialog} from '@lib/hooks/useDialog';
import {Typography} from '@mui/material';
import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {LoadingDialog} from '../../common/components/dialogs/LoadingDialog';
import {CreateKontaktDialog} from './dialogs/CreateKontaktDialog';
import {KontaktniUdajeProNaseptavani} from './forms/CreateKontaktForm';
import {KontaktyPrehled} from './prehled/KontaktyPrehled';
import {initialKontaktyProNaseptavani, transformKontaktyProNaseptavani} from './utils/transformKontaktyForNaseptavani';

type Props = {
    readonly toggleAuthentication: (value: boolean) => void;
    readonly isCheckingAuthorization: boolean;
    readonly toggleIsCheckingAuthorization: (isCheckingAuthorization?: boolean) => void;
};

export const Kontakty: React.FC<Props> = ({toggleAuthentication, toggleIsCheckingAuthorization, isCheckingAuthorization}) => {
    const [deletingKontakt, setDeletingKontakt] = useState<Kontakt | null>(null);
    const [isDeletingKontakt, setIsDeletingKontakt] = useState(false);
    const [isCreatingKontakt, setIsCreatingKontakt] = useState(false);
    const [creatingKategorie, setCreatingKategorie] = useState<NotifikaceKategorie | null>(null);

    const [kontaktniUdajeProNaseptavani, setKontaktniUdajeProNaseptavani] = useState<KontaktniUdajeProNaseptavani>(initialKontaktyProNaseptavani);

    const {addNotification} = useAppContext();
    const {close: closeDeletingDialog, open: openDeletingDialog, isOpen: isDeletingDialogOpen} = useDialog();

    const {data: ucetSkupinaData, loading: getMyUcetSkupinaQueryLoading} = useQuery<Query>(GET_MY_UCET_SKUPINA_QUERY, {
        fetchPolicy: 'network-only',
    });

    const {
        data,
        loading,
        refetch: refetchKontakty,
    } = useQuery<Query>(ZISKAT_KONTAKTY_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: async (data) => {
            toggleIsCheckingAuthorization(false);

            const kontakty = data?.kontakty.ziskatKontakty ?? [];

            const kontaktyTransformedForNaseptavani = transformKontaktyProNaseptavani(kontakty);

            setKontaktniUdajeProNaseptavani(kontaktyTransformedForNaseptavani);
        },
        onError: () => {
            toggleAuthentication(false);

            sessionStorage.removeItem(LOGGED_KEY);

            toggleIsCheckingAuthorization(false);
            toggleIsCheckingAuthorization(false);

            addNotification({type: NotificationType.ERROR, text: <FormattedMessage id="error.authentication" />});
        },
    });

    const [deleteKontaktMutation, {loading: loadingDeleteKontakt}] = useMutation<Mutation>(DELETE_KONTAKT_MUTATION, {
        onCompleted: () => {
            addNotification({text: <FormattedMessage id="kontakty.smazat" />});

            setIsDeletingKontakt(false);
            setDeletingKontakt(null);

            refetchKontakty();

            closeDeletingDialog();
        },
        onError: (error) => {
            setIsDeletingKontakt(false);
            addNotification({type: NotificationType.ERROR, text: error.graphQLErrors[0].message});
        },
    });

    if (loading || getMyUcetSkupinaQueryLoading || isCheckingAuthorization || loadingDeleteKontakt) {
        return <LoadingDialog open />;
    }

    const toggleIsCreating = (isCreating: boolean) => {
        setIsCreatingKontakt(isCreating);

        if (!isCreating) {
            setCreatingKategorie(null);
        }
    };

    const handleAddKontakt = (kategorie: NotifikaceKategorie) => {
        setIsCreatingKontakt(true);
        setCreatingKategorie(kategorie);
    };

    const handleDeleteKontakt = (kontakt: Kontakt) => {
        setDeletingKontakt(kontakt);

        openDeletingDialog();
    };

    const handleCloseDeleteKontaktDialog = () => {
        setDeletingKontakt(null);
        closeDeletingDialog();
    };

    const confirmKontaktDeletion = () => {
        setIsDeletingKontakt(true);

        deleteKontaktMutation({variables: {id: deletingKontakt?.id}});
    };

    const kontakty = data?.kontakty.ziskatKontakty ?? [];

    const adresaObchodnihoPartnera = ucetSkupinaData?.ucetSkupina?.getMyUcetSkupina?.obchodniPartner?.adresa ?? null;

    const smlouvyOm = data?.elektrina?.odbernaMista?.smlouvyOdbernychMist ?? {edges: []};

    const maVnVvn =
        smlouvyOm?.edges
            ?.map((edge) => edge?.node?.elektrina)
            ?.filter((f) => f?.napetovaUroven === NapetovaUroven.VN || f?.napetovaUroven === NapetovaUroven.VVN)?.length > 0;

    const skupinyNotifikaci = data?.kontakty?.skupinyNotifikaci ?? [];

    const ucetSkupina = ucetSkupinaData?.ucetSkupina?.getMyUcetSkupina ?? null;

    const povoleneDruhyKomunikaceVRamciVytvareneKategorie = creatingKategorie
        ? creatingKategorie.povoleneDruhyKomunikace.filter(
              /* This obchodni partner ma VnVVn
               */
              (druh) => druh !== 'LTR' || maVnVvn,
          )
        : [];

    return (
        <>
            <KontaktyPrehled
                kontakty={kontakty}
                skupinyNotifikaci={skupinyNotifikaci}
                onAddItem={handleAddKontakt}
                onDeleteItem={handleDeleteKontakt}
                ucetSkupina={ucetSkupina}
            />

            {/* Delete Confirmation Dialog */}
            {isDeletingDialogOpen && (
                <ConfirmDialog
                    open
                    onClickNo={handleCloseDeleteKontaktDialog}
                    onClickYes={confirmKontaktDeletion}
                    loading={isDeletingKontakt}
                    description={
                        <Typography>
                            <FormattedMessage id="kontakty.odstranit" values={{value: <strong>{deletingKontakt?.kontaktniUdaj}</strong>}} />
                        </Typography>
                    }
                />
            )}

            {/* Create Kontakt Dialog */}
            {isCreatingKontakt && !!creatingKategorie && (
                <CreateKontaktDialog
                    open
                    toggleIsCreating={toggleIsCreating}
                    kontaktniUdajeProNaseptavani={kontaktniUdajeProNaseptavani}
                    kategoryInWhichKontaktIsBeingCreated={creatingKategorie}
                    refetchKontakty={refetchKontakty}
                    adresaObchodnihoPartnera={adresaObchodnihoPartnera}
                    druhyKomunikace={povoleneDruhyKomunikaceVRamciVytvareneKategorie}
                    disableCommodities={!creatingKategorie?.elektrina || !creatingKategorie?.plyn /*|| !me?.skupina?.plyn || !me?.skupina?.elektrina*/}
                    showCommodities={creatingKategorie?.povolitVyberKomodity}
                    showEan={creatingKategorie?.povolitVyberEAN}
                    showEic={creatingKategorie?.povolitVyberEIC}
                    initialValues={{
                        elektrina: creatingKategorie?.elektrina,
                        plyn: creatingKategorie?.plyn,
                        adresa: adresaObchodnihoPartnera !== null ? adresaObchodnihoPartnera : {},
                    }}
                />
            )}
        </>
    );
};
